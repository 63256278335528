// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "jquery"

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

class TabToggler{
  constructor(tabName){
    this.currentActive = ""
    this.tabName = tabName
    this.tabSelector = "."+tabName
  }

  setActive(){
    $(this.tabSelector).each((index, el)=>{
      if($(el).hasClass("active")){
        this.currentActive = $(el)
      }
    })
  }

  resetContentContainers(){
    $(".feature-content").each((index, el)=>{
      $(el).removeClass("active")
    })
  }

  assignActive(target, className){
    $(this.tabSelector).each((index, el)=>{
      $(el).removeClass("active")
    })

    if($(target).hasClass(className)){
      this.resetContentContainers()
      $(target).addClass("active")
      $("."+$(target).data("tab-toggle")).addClass("active")
      this.setActive()
    }
    
  }
}

$(document).ready((e)=>{
  // $(document).on('turbolinks:load', function() {
    if ( $(".main-feature-panel").length != 0 ){
      const klass = "feature-tab"
      const toggler = new TabToggler(klass)
      toggler.setActive();

      $(".feature-tab, .feature-add, .feature-description").hover((el)=>{
        let tar = undefined
        
        if($(el.target).hasClass(klass)){
          tar = $(el.target)
        } else {
          tar = $(el.target).parents(".feature-tab")[0]
        }

        toggler.assignActive(tar, klass)
      })
    }  

    $("#explore-employee").click(function(e) {
        e.preventDefault()
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".employee-section").offset().top
        }, 100);
    });  

    $("#explore-business").click(function(e) {
        e.preventDefault()
        $([document.documentElement, document.body]).animate({
            scrollTop: $("body").offset().top
        }, 100);
    });    

    $("#explore-careers").click(function(e) {
        e.preventDefault()
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".jobs-section").offset().top - 10
        }, 100);
    }); 


  // })



  

})

